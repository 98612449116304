import React from 'react';
import ReactDOM from 'react-dom';
import './style.css';
//https://www.freecodecamp.org/news/how-to-validate-forms-in-react/
import MultiStepForm from './MultiStepForm';
import { AppProvider } from './state';

function App() {
  return (
    <AppProvider>
      <div className="container-fluid container-padding">
        <div className="app">
          <div className="wrapper">
            <div className="d-flex">
              <div className="w-100">
                <div className="d-md-flex justify-content-md-between align-items-center spacer-section">
                  <div className="flex-fill mb-6 mb-md-0">
                    <h1 className="pr-md-6 ">اتمام الاشتراك</h1>
                  </div>
                  <div className="mt-n2 mt-md-0"></div>
                </div>
              </div>
            </div>
            <div className="sidebar-container right">
              <div className="main-content card-container">
                <MultiStepForm />
              </div>
              <div className="sidebar">
                {' '}
                <div id="summary">
                  <div
                    className="card card-sm"
                    data-controller="summary"
                    data-action="checkout:submit@window->summary#disableInputs checkout:submit-cancel@window->summary#enableInputs"
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-start d-md-block">
                        <img
                          className="thumb thumb-lg d-md-none mr-3"
                          src="https://anbilarabi.com/wp-content/uploads/2023/07/masaq-it-w1-min.png"
                        />
                        <img
                          className="img-fluid rounded d-none d-md-block"
                          src="https://anbilarabi.com/wp-content/uploads/2023/07/masaq-it-w1-min.png"
                        />
                        <div className="w-100">
                          <h3 className="mt-md-4 mb-1">
                            Masaq.it Complete Fullstack Course
                          </h3>
                          <div className="bulleted">
                            <span className="bulleted-item">
                              <span>$1800</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppProvider>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
