import React from 'react';

const ItemForm = ({ label, children, type = 'text', ...otherProps }) => (
  <div className="form-group">
    {type === 'text' ? (
      <>
        <label className="required">{label}</label>
        <input className="form-control" required type={type} {...otherProps} />
      </>
    ) : (
      <>
        <label className="required">{label}</label>
        <input className="form-control" required type={type} {...otherProps} />
      </>
    )}
  </div>
);

export default ItemForm;
