import React, { useRef, useState, useEffect } from 'react';

import ItemForm from './ItemForm';
import StateDrop from './StateDrop';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useForm, useStep } from 'react-hooks-helper';
import { useAppState } from './state';
import validator from 'validator';
const Phone = ({ setForm, formValidation }) => {
  const [state, setState] = useAppState();

  return (
    <PhoneInput
      value={state.phone}
      autoFormat
      country={'ps'}
      enableSearch={true}
      disableSearchIcon={true}
      preferredCountries={['ps', 'sa']}
      inputClass="form-control"
      containerClass="form-group"
      dropdownClass="dropd"
      placeholder="Enter phone number"
      countryCodeEditable={false}
      onChange={(v, e, ee) => {
        console.log(v, e, ee);
        console.log(state);
        setState({ phone: v });
        formValidation();
        // setState({ ...state, ...data });
      }}
      inputProps={{
        id: 'whatsapp',
      }}
    />
  );
};

const ContactStep2 = ({ setForm, error, setError, formData, navigation }) => {
  const { email, fullname, phone } = formData;
  const [state, setState] = useAppState();
  const [buttondisabled, setButtondisabled] = useState(false);
  const formValidation = () => {
    let errors = [];

    if (validator.isEmail(email)) {
      errors.email = '';
    } else {
      errors.email = 'البريد الالكتروني مطلوب';
    }

    if (validator.isEmpty(fullname)) {
      errors.fullname = 'الاسم الكامل بالانجليزية مطلوب';
    } else {
      errors.fullname = '';
    }

    if (state && state.phone) {
      if (validator.isEmpty(state.phone)) {
        errors.phone = 'رقم الواتساب للتواصل مطلوب';
      } else {
        errors.phone = '';
      }
    } else {
      errors.phone = 'رقم الواتساب للتواصل مطلوب';
    }

    console.log(errors);
    setError(errors);
  };

  const subscribe = () => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow',
    };

    let list_uid = '62296cde07302'
    let token = '5ISowQrSYEbZlRRq9A0QwGscbnqtv8oHuvhbitn7q6HrbUxpsaUqyakONjDg';

   let email= formData.email
   let fullname = formData.fullname 
   let phone=state.phone
    let _url = `https://greenbackend.email/api/v1/subscribers?api_token=${token}&list_uid=${list_uid}&EMAIL=${email}&tag=masaq-checkout&FIRST_NAME=${fullname}&WHATSAPP=${phone}`;
    console.log(_url)
    fetch(
      _url,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log('error', error));
  };
  const { previous, next } = navigation;

  if (formData.email == '' || formData.fullname == '' || state.phone == '') {
  } else {
  }

  return (
    <div className="card">
      <div className="card-header ">
        <div className="d-flex justify-content-between">
          <div>
            <div className="card-title">تفاصيل الحساب</div>
            <div className="card-subtitle"></div>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="form">
          <ItemForm
            label="الاسم الكامل"
            name="fullname"
            value={fullname}
            onChange={(e) => {
              setForm(e);
              formValidation();
            }}
            placeholder="e.g Ahmad Naser"
          />
          <label htmlFor="whatsapp">رقم الواتساب</label>
          <Phone
            setForm={setForm}
            value={phone}
            formValidation={formValidation}
          />
          <ItemForm
            label="البريد الالكتروني"
            name="email"
            value={email}
            onChange={(e) => {
              setForm(e);
              formValidation();
            }}
            placeholder="e.g you@gbe.com"
          />

          <div>
            {error ? (
              <>
                {Object.entries(error).map((err) => (
                  <span style={{ color: 'red', display: 'block' }}>
                    {err[1] ? <>{err[1]}</> : <></>}
                  </span>
                ))}
              </>
            ) : (
              ''
            )}
            <button className="btn btn-md btn-dark" onClick={previous}>
              السابق
            </button>
            <button
              className={`btn btn-md btn-dark ml-1 ${
                formData.email == '' ||
                formData.fullname == '' ||
                state.phone == ''
                  ? 'disabled'
                  : ''
              }`}
              onClick={(e) => {
                if (
                  formData.email == '' ||
                  formData.fullname == '' ||
                  state.phone == ''
                ) {
                  formValidation();
                } else {
                  subscribe();
                  next(e);
                }
              }}
            >
              التالي
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactStep2;
