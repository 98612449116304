import React from 'react';
import './widget.css';
import { useAppState } from './state';

const Review = ({ setForm, formData, navigation }) => {
  const { fullname, email } = formData;
  const [state, setState] = useAppState();
  const course_name = 'Masaq.it Complete Fullstack Course';
  console.log(state);
  const { go } = navigation;

  const paymentOptions = [
    {
      id: 1,
      desc: 'دفع كاش لمرة واحدة $1800',
      slips: false,
      payment: 1800,
      total: 1800,
      futurePayments: 0,
      paymentLink: 'https://buy.stripe.com/3cs14a5jF0Hy9hK009',
    },
    {
      id: 2,
      desc: 'دفع تقسيط على 3 دفعات',
      slips: true,
      payment: 600,
      total: 1800,
      futurePayments: 2,
      paymentLink:
        'https://cp.greenbackend.com/store/courses-dwrt/masaqit-payments',
    },
    {
      id: 3,
      desc: 'دفع تقسيط على 9 دفعات',
      slips: true,
      payment: 200,
      total: 1800,
      futurePayments: 8,
      paymentLink:
        'https://cp.greenbackend.com/store/courses-dwrt/masaqiteight-pays',
    },
  ];

  const paymentSelected = paymentOptions.filter(
    (option) => option.id == state.paymentOption
  )[0];
  console.log();
  return (
    <div className="form gbe-review">
      <h3>تأكيد معلومات طلبك</h3>

      <h4 className="gbe-revew-headline">
        تفاصيلك
        <button onClick={() => go('contactstep2')}>تعديل</button>
      </h4>
      <div className="gbe-revew-content">
        Name: {`${fullname}`}
        <br />
        Email: {` ${email}`}
        <br />
        Whatsapp: {`${state.phone}`}
      </div>
      <h4 className="gbe-revew-headline">
        طريقة الاشتراك
        <button onClick={() => go('paymentoptionsstep3')}>تعديل</button>
      </h4>
      <div className="gbe-revew-content">
        {paymentSelected.desc}
        <br /> <br />
        <div class="pos pos-image">
          <div
            className="builder-v2-block builder-v2-block-image"
            data-block="seal"
          >
            <img
              src="https://anbilarabi.com/wp-content/uploads/2023/10/guarantee_4.png"
              className="builder-v2-block-image-img img"
            />
          </div>{' '}
        </div>
        <div className="builder-v2-block builder-v2-block-spaced builder-v2-block-core_pricing_breakdown tc-border_style-solid">
          <ul className="order-details order-details-onetime hidden">
            <li className="order-details-heading order-details-heading-today">
              دفعة اليوم
            </li>
            <li
              className="order-details-row order-details-main"
              data-quantity={1}
            >
              <span className="multibuy-counter">
                <span>1</span>x
              </span>
              <span className="order-details-section">
                <span className="order-details-name">{course_name}</span>
                <em>${paymentSelected.payment}</em>
              </span>
              <div className="flex-break" />
              <span className="multibuy-action multibuy-action-input">
                <input
                  type="number"
                  min={1}
                  max={100}
                  placeholder={1}
                  defaultValue={1}
                />
              </span>
              <span className="multibuy-action multibuy-action-minus">
                <span>-</span>
              </span>
              <span className="multibuy-action multibuy-action-plus">
                <span>+</span>
              </span>
            </li>
          </ul>
          <ul className="order-details order-details-recurring">
            <li className="order-details-heading order-details-heading-today">
              دفعة اليوم
            </li>
            <li
              className="order-details-row order-details-main"
              data-quantity={1}
            >
              <span className="order-details-section">
                <span className="order-details-name">{course_name}</span>
                <em>${paymentSelected.payment}</em>
              </span>
            </li>

            {paymentSelected.slips ? (
              <>
                <li className="order-details-heading order-details-heading-subscription">
                  الدفعات المستقبلية المتبقية
                </li>
                <li className="order-details-row order-details-subscription order-details-limitedrebills">
                  <span
                    data-balloon-prefix="Total product price: "
                    data-balloon-pos="up"
                    data-balloon="Total product price: $357"
                  >
                    {paymentSelected.futurePayments} دفعات شهرية
                    <i className="fa fa-fw fa-info-circle order-details-subscription-icon" />
                  </span>
                  <em data-currency="$">${paymentSelected.payment}</em>{' '}
                </li>
              </>
            ) : (
              <></>
            )}

            <li className="order-details-total order-details-total-today">
              <span>مجموع دفعة اليوم:</span>
              <strike data-currency="$" />
              <em data-currency="$">${paymentSelected.payment}</em>
            </li>

            <li className="order-details-total order-details-total-total hidden">
              <span>&nbsp;</span>
              <strike data-currency="$" />
              <em data-currency="$">$0</em>
            </li>
          </ul>
          <p className="currency">All prices in USD</p>
        </div>
      </div>
      <div>
        <button
          className="ordernow"
          onClick={() => {
            setState({
              phone: state.phone,
              paymentOption: state.paymentOption,
              paymentLink: paymentSelected.paymentLink,
              fullname: fullname,
              email: email,
            });
            go('submit');
          }}
        >
          اتمام الدفع
        </button>
      </div>
    </div>
  );
};

export default Review;
