import React, { useState } from 'react';
import { useForm, useStep, setValue } from 'react-hooks-helper';
//https://claritydev.net/blog/build-a-multistep-form-with-react-hook-form
import EmailStep1 from './EmailStep1';
import PaymentOptionsStep3 from './PaymentOptionsStep3';
import ContactStep2 from './ContactStep2';
import Review from './Review';
import Submit from './Submit';
import validator from 'validator';
import './style.css';

const steps = [
  { id: 'emailstep1' },
  { id: 'contactstep2' },
  { id: 'paymentoptionsstep3' },
  { id: 'review' },
  { id: 'submit' },
];

const defaultData = {
  fullname: '',
  lastName: 'Doe',
  nickName: 'Jan',
  address: '200 South Main St',
  city: 'Anytown',
  state: 'CA',
  zip: '90505',
  email: '',
  phone: '',
};

const MultiStepForm = ({ images }) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const [error, setError] = useState(false);
  const props = { formData, setForm, navigation, error, setError };

  switch (id) {
    case 'emailstep1':
      return <EmailStep1 {...props} />;
    case 'paymentoptionsstep3':
      return <PaymentOptionsStep3 {...props} />;
    case 'contactstep2':
      return <ContactStep2 {...props} />;
    case 'review':
      return <Review {...props} />;
    case 'submit':
      return <Submit {...props} />;
    default:
      return null;
  }
};

export default MultiStepForm;
