import React, { useRef, useState, useEffect } from 'react';

import ItemForm from './ItemForm';
import './widget.css';

import { FaCheckCircle } from 'react-icons/fa';
import { useAppState } from './state';
const PaymentOptionsStep3 = ({ setForm, formData, navigation }) => {
  const { phone, email } = formData;

  const { previous, next } = navigation;

  const payOption1 = useRef();
  const payOption2 = useRef();
  const payOption3 = useRef();
  const [value, setvalue] = useState('1');
  const [state, setState] = useAppState();
  const count = useRef(null);

  const runOnce = () => {
    console.log('Function Triggered');
    setvalue('1');

    payOption1.current.value = '1';
    payOption1.current.classList.add('active');
    payOption2.current.classList.remove('active');
    payOption3.current.classList.remove('active');
    setState({ phone: state.phone, paymentOption: value });
    handleClick(1);
  };

  useEffect(() => {
    if (count.current == null) {
      runOnce();
    }
    return () => {
      count.current = 1;
    };
  }, []);

  const handleClick = (v) => {
    if (v == 1) {
      setvalue(payOption1.current.value);
      payOption1.current.classList.add('active');
      payOption2.current.classList.remove('active');
      payOption3.current.classList.remove('active');
      console.log(v);
    } else if (v == 2) {
      setvalue(payOption2.current.value);
      payOption2.current.classList.add('active');
      payOption1.current.classList.remove('active');
      payOption3.current.classList.remove('active');
      console.log(v);
    } else if (v == 3) {
      setvalue(payOption3.current.value);
      payOption3.current.classList.add('active');
      payOption1.current.classList.remove('active');
      payOption2.current.classList.remove('active');
      console.log(v);
    }
    setState({ phone: state.phone, paymentOption: v });
  };

  return (
    <div className="form">
      <p>اختر الية الاشتراك الخاصة بك </p>
      <ul className="list-payment_plans ">
        <li
          value={1}
          className="list-payment_plans-row "
          ref={payOption1}
          onClick={() => handleClick(1)}
        >
          <div className="list-payment_plans-inner">
            <span className="list-payment_plans-marker-active">
              {payOption1.current &&
              payOption1.current.classList.contains('active') ? (
                <FaCheckCircle />
              ) : (
                <></>
              )}
            </span>
            <span className="list-payment_plans-name">
              <span className="list-payment_plans-name-inner">
                One-time payment ($1800.00)
              </span>
              <span className="list-payment_plans-price">$1800.00</span>
            </span>
          </div>
        </li>
        <li
          value={2}
          className="list-payment_plans-row "
          ref={payOption2}
          onClick={() => handleClick(2)}
        >
          <div className="list-payment_plans-inner">
            <span className="list-payment_plans-marker-active">
              {payOption2.current &&
              payOption2.current.classList.contains('active') ? (
                <FaCheckCircle />
              ) : (
                <></>
              )}
            </span>
            <span className="list-payment_plans-name">
              <span className="list-payment_plans-name-inner">
                3 Payments of $600
              </span>
              <span className="list-payment_plans-price">3x $600.00</span>
            </span>
          </div>
        </li>
        <li
          value={3}
          className="list-payment_plans-row "
          ref={payOption3}
          onClick={() => handleClick(3)}
        >
          <div className="list-payment_plans-inner">
            <span className="list-payment_plans-marker-active">
              {payOption3.current &&
              payOption3.current.classList.contains('active') ? (
                <FaCheckCircle />
              ) : (
                <></>
              )}
            </span>
            <span className="list-payment_plans-name">
              <span className="list-payment_plans-name-inner">
                9 Payments of $200
              </span>
              <span className="list-payment_plans-price">9x $200.00</span>
            </span>
          </div>
        </li>
      </ul>

      <div>
        <button className="btn btn-md btn-dark ml-1" onClick={previous}>
          السابق
        </button>
        <button className="btn btn-md btn-dark ml-1" onClick={next}>
          التالي
        </button>
      </div>
    </div>
  );
};

export default PaymentOptionsStep3;
