import React, { useRef, useState, useEffect } from 'react';

import { useAppState } from './state';

const Submit = ({ navigation }) => {
  const [state, setState] = useAppState();
  /*  setState({
              phone: state.phone,
              paymentOption: state.paymentOption,
              paymentLink: paymentSelected.paymentLink,
              fullname: fullname,
              email: email,
            });*/
  useEffect(() => {
    const timeout = setTimeout(() => {
      // 👇️ redirects to an external URL
      //   window.location.replace(state.paymentLink);
      window.open(state.paymentLink, '_blank', 'noopener,noreferrer');
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const { go } = navigation;
  return (
    <div>
      <h3>جاري تحويلك...</h3>
      تريد عمل طلب جديد؟ {'->'}{' '}
      <button onClick={() => go('emailstep1')}>طلب جديد</button>
    </div>
  );
};

export default Submit;
