import React, { useRef, useState, useEffect } from 'react';

import ItemForm from './ItemForm';
import validator from 'validator';
const EmailStep1 = ({ setForm, formData, navigation, error, setError }) => {
  const { email } = formData;

  const formValidation = () => {
    if (validator.isEmail(email)) {
      setError(null);
    } else {
      setError('الرجاء ادخال قيمة صحيحة للبريد الالكتروني');
    }
  };
  const { next } = navigation;

  return (
    <div className="card">
      <div className="card-header ">
        <div className="d-flex justify-content-between">
          <div>
            <div className="card-title">معلومات التسجيل</div>
            <div className="card-subtitle">
              قم بادخال بريدك الالكتروني للتسجيل في الدورة
            </div>
          </div>
        </div>
      </div>

      <div className="card-body">
        <div className="form">
          <ItemForm
            label="Email"
            name="email"
            value={email}
            onChange={(e) => {
              setForm(e);
              formValidation();
            }}
            placeholder="e.g you@gbe.com"
          />

          {error ? <span style={{ color: 'red' }}>{error}</span> : ''}

          <div>
            <button
              className={`btn btn-md btn-dark ml-1 ${error ? 'disabled' : ''}`}
              onClick={(e) => {
                if (formData.email == '' || error) {
                  formValidation();
                } else {
                  next(e);
                }
              }}
            >
              التالي
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailStep1;
